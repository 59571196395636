import { IQuizContext } from './QuizContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { FormValues } from '@wix/form-viewer/widget';
import { initFormController } from '@wix/form-viewer/controller';
import {
  createSubmission as createSubmissionQuizService,
  getSubmission,
} from '@wix/ambassador-achievements-quizzes-v1-quiz-submission/http';
import { getFormSubmissionValues, getSubmissionAnswers } from './selectors';

type SetQuizProps = (patch: Partial<IQuizContext>) => void;

export const quizPropsMap = (flowAPI: ControllerFlowAPI): IQuizContext => {
  const setProps: SetQuizProps = flowAPI.controllerConfig.setProps;

  return {
    controllerIsReady: false,
    initController: async (formId: string) => {
      setProps({
        controllerIsReady: false,
        lastSubmissionId: null,
      });

      await initFormController(flowAPI, {
        formId,
      });

      setProps({
        controllerIsReady: true,
      });
    },
    sendSubmission: async (quizId: string, values: FormValues) => {
      // from submission values to quiz answers
      const answers = {};
      Object.keys(values).forEach((key) => {
        answers[key] = {
          value: values[key],
        };
      });

      const sbmsn = await flowAPI.httpClient.request(
        createSubmissionQuizService({
          submission: {
            quizId,
            answers,
          },
        }),
      );

      flowAPI.controllerConfig.setProps({
        lastSubmissionId: sbmsn?.data?.submission?.id,
        submission: {
          submissions: getFormSubmissionValues(sbmsn?.data?.submission),
          answers: getSubmissionAnswers(sbmsn?.data?.submission),
        },
      });
    },
    lastSubmissionId: null,
    getSubmission: async (submissionId: string) => {
      const sbmsn = (
        await flowAPI.httpClient.request(
          getSubmission({
            submissionId,
          }),
        )
      )?.data;

      flowAPI.controllerConfig.setProps({
        submission: {
          submissions: getFormSubmissionValues(sbmsn?.submission),
          answers: getSubmissionAnswers(sbmsn?.submission),
        },
      });
    },
    submission: null,
  };
};
