import { FormValues } from '@wix/form-viewer/widget';
import { QuizSubmission } from '@wix/ambassador-achievements-quizzes-v1-quiz-submission/types';

export function getSubmissionAnswers(sbmsn?: QuizSubmission) {
  return sbmsn?.answers || {};
}

export function getFormSubmissionValues(sbmsn?: QuizSubmission): FormValues {
  const answers = getSubmissionAnswers(sbmsn);
  return Object.entries(answers).reduce<FormValues>(
    (previousValue, [key, val]) => {
      previousValue[key] = val.value;
      return previousValue;
    },
    {},
  );
}
